import { fetchMethods } from 'enums'
import fetchData from 'helpers/fetchData'

export const getDevices = ({
	appRelease,
	deviceFamilyId,
	firmware,
	pageSize,
	pageIndex,
	partner,
	status,
	isCompanion,
	dateFrom,
	dateTo,
	q,
	signal,
}) =>
	fetchData(
		fetchMethods.GET,
		`v1/devices?pageSize=${pageSize}&currentPage=${
			pageIndex + 1
		}&search=${q}&filters.deviceFamilyId=${deviceFamilyId}&filters.isOnline=${status}&filters.partnerId=${partner}&filters.buildNumber=${firmware}&filters.appversion=${appRelease}&filters.date=${dateFrom}&filters.dateTo=${dateTo}&isCompanion=${isCompanion}`,
		{ signal }
	)

export const getModalDevices = (search, signal) =>
	fetchData(fetchMethods.GET, `v1/devices?pageSize=10&search=${search}`, {
		signal,
	})

export const getOsDeploymentClientDevices = ({ clientId, deviceType, deviceFamilyId, companionMode, signal }) => {
	const companionDevice = 'companion'
	const path =
		deviceType === companionDevice
			? `/companions?deviceFamilyId=${deviceFamilyId}&companionModeId=${companionMode}`
			: `?deviceFamilyId=${deviceFamilyId}`

	const url = `v1/device-partners/clients/${clientId}/pools/devices${path}`
	return fetchData(fetchMethods.GET, url, { signal })
}

export const getDeviceDetails = (serial, deviceId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/details?deviceId=${deviceId}`, { signal })

export const getPopoverDeviceDetails = (serial) => fetchData(fetchMethods.GET, `v1/devices/${serial}/mdm-details`)

export const getMlModelDevices = ({ search, companyId, teamId, teamTypeId, mlModelId, version, currentPage, signal }) =>
	fetchData(
		fetchMethods.GET,
		`v1/companies/${companyId}/teams/${teamId}/team-types/${teamTypeId}/ml-models/${mlModelId}/versions/${version}/status?currentPage=${
			currentPage + 1
		}&pageSize=10&search=${search}`,
		{ signal }
	)

export const getDeviceConfigurations = (deviceId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${deviceId}/configurations`, {
		signal,
	})

export const getDeviceTreeview = (id, companyId) =>
	fetchData(fetchMethods.GET, `v1/devices/${id}/companies/${companyId}/location`)

export const getOngoingConferences = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/conferences/ongoing`, { signal })

export const getPastConferences = (serial, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/conferences/past`, { signal })

export const getReceivedLogs = (serial, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/logs?currentPage=1&pageSize=300`, { signal })

export const getStatusLogs = (serial, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${serial}/status-logs?currentPage=1`, { signal })

export const getCommandLogs = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/command-logs?pageSize=50`, { signal })

export const getAllowList = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/allowlist/logs`, { signal })

export const getActiveOfflineCommands = (solHelloId, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/offline-commands/all`, { signal })

export const getDeviceFamily = (search, signal) => fetchData(fetchMethods.GET, `v1/devices/families?search=${search}`, { signal })

export const getDeviceEditInfo = (solHelloId, signal) => fetchData(fetchMethods.GET, `v1/devices/${solHelloId}`, { signal })

export const getUnasignedDevicesDropdown = (search, signal) =>
	fetchData(fetchMethods.GET, `v1/devices/v1.1/unallocated?search=${search}`, { signal })

export const getUnassociatedDevices = (search, signal) =>
	fetchData(fetchMethods.GET, `v1/device-pools/unassociated-devices?search=${search}`, { signal })

export const getCompanionModes = () => fetchData(fetchMethods.GET, `v1/devices/companion/modes`)

export const createDevice = (formObj) => fetchData(fetchMethods.POST, `v1/devices`, { body: JSON.stringify(formObj) })

export const transferToPartner = (formObj) =>
	fetchData(fetchMethods.POST, `v1/devices/re-transfer`, { body: JSON.stringify(formObj) })

export const transferToPartnerBulk = (formObj) =>
	fetchData(fetchMethods.POST, `v1/devices/transfer`, { body: JSON.stringify(formObj) })

export const sendCommand = ({ isOnline, deviceId, ...rest }) => {
	const url = `v1/devices/${deviceId}/${isOnline ? 'command' : 'offline-commands'}`
	return fetchData(fetchMethods.POST, url, { body: JSON.stringify({ deviceId, ...rest }) })
}

export const editDeviceWithId = (formObj, deviceId) =>
	fetchData(fetchMethods.PUT, `v1/devices/${deviceId}`, { body: JSON.stringify(formObj) })

export const deleteDeviceWithId = (deviceId) => () => fetchData(fetchMethods.DELETE, `v1/devices/${deviceId}`)

export const removeFromPartner = (deviceId) => () => fetchData(fetchMethods.DELETE, `v1/devices/device-of-partner/${deviceId}`)
